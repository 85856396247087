<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">里德工业园区储能光伏备电一体化项目正式投入运行</div>
          <div class="notice-time">
            <div>发布时间： 2022/04/25 11:26:31</div>
            <div>浏览： 138次</div>
          </div>
          <p>
            2022年3月24日，杭州里德通信有限公司桐庐生产基地园区储能光伏备电一体化示范项目正式投入运行，该项目是浙江省首例县域储能+光伏+备电一体化工业园区示范工程，标志着里德在推动工业园区低碳转型、清洁生产迈出了坚实的一步。
          </p>

          <p style="margin-top: 15px;">
            该项目安装了容量1.8MWp的光伏发电系统，配置一套0.8MWh用户侧储能系统，实现上有电厂下有工厂的立体用能。整个项目系统解决方案的核心产品，均采用里德通信自主研发的高可靠高性能产品,保证整个系统能够实现更高稳定性、安全性和兼容性，同时搭建了数字化能源管理平台，将能源生产消费过程可视化，用户用能便捷高效。
          </p>
          <div class="notice-img-1">
            <img src="./image27.png" srcset="./image27@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <!-- <img src="./2.png" srcset="./2@2x.png 2x" alt=""> -->
          </div>
          <p style="margin-top: 15px;">
            该项目的运行，不仅有效降低企业用电扩容需求，同时利用储能备电的并离网技术有效的降低了企业用电风险，提高了企业用电安全，更重要的是能够有效进行碳减排，从而为碳达峰碳中和助力，预计每年可减排二氧化碳1082吨。
          </p>
          <div class="notice-img-1">
            <img src="./image28.png" srcset="./image28@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <!-- <img src="./2.png" srcset="./2@2x.png 2x" alt=""> -->
          </div>
          <p style="margin-top: 15px;">
            在数智双碳的时代背景下，里德不忘初心，深耕于BMS电池管理系统的专业设计及智造服务，为客户提供全生命周期安全可靠的产品和极具性价比的整体解决方案，为实现碳达峰碳中和目标贡献小小的“里德力量”。 </p>
          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice12">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice10">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1,
.notice-img-2 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
